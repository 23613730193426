
import Vue from "vue"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { camelizeKeys } from "humps"
import {
  AnalyticsEvent,
  AnalyticsEventPageId,
} from "@evercam/shared/types/analytics"

export default Vue.extend({
  layout: "clean",
  meta: {
    pageId: AnalyticsEventPageId.progressPhotosUnsubscribe,
  },
  data() {
    return {
      unsubscribed: false,
    }
  },
  computed: {
    routeCameraExid() {
      const camelizedKeys = camelizeKeys(this.$route.params) as {
        cameraExid: string
      }

      return camelizedKeys?.cameraExid
    },
  },
  mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.pageView)
  },
  methods: {
    unsubscribe() {
      this.$analytics.saveEvent(AnalyticsEvent.progressPhotoUnsubscribeUser)
      EvercamApi.progressPhotos
        .unsubscribe(this.$route.params.id, this.$route.query.email as string)
        .then(() => {
          this.$notifications.success(
            "Progress Photo settings updated successfully."
          )
          this.unsubscribed = true
          this.$router.push("/v2/automations/progress-photos")
        })
    },
  },
})
